import {
  APPOINTMENTS,
  ORGANIZATION,
  ACCOUNT_SELECTION,
  USERS_ACCOUNT_SELECTION,
} from "@router/consts";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "@contexts/User";
import { USER_ROLES, CUSTOM_USER_ROLES } from "@utils/consts";
import AccountGraphqlInstance from "@services/api/account.graphql";
import { ability } from "ability/ability";
import { PROFILE } from "@router/consts";
import { useLocation } from "react-router-dom";
import { STUDENT_ROUTES } from "@router/student";
import { COUNSELLOR_ROUTES } from "@router/counsellor";
import { PRINCIPAL_ROUTES } from "@router/principal";
import { ALLBRY_COUNSELLOR_ROUTES } from "@router/allbryCounsellor";

export const RedirectRoot = () => {
  const { profile } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const routeRoles = {
    [USER_ROLES.STUDENT_ROLE]: STUDENT_ROUTES(),
    [USER_ROLES.COUNSELLOR_ROLE]: COUNSELLOR_ROUTES(),
    [USER_ROLES.PRINCIPAL_ROLE]: PRINCIPAL_ROUTES(),
    [USER_ROLES.ALLBRY_COUNSELLOR_ROLE]: ALLBRY_COUNSELLOR_ROUTES,
  };

  const UsersResponse = async () => {
    const data = await AccountGraphqlInstance.getAccounts();
    return data;
  };

  function isPathInArray(path, array) {
    for (let arrayPath of array) {
      // Escape special characters in the array path and create a regular expression pattern
      const pattern = new RegExp(`^${arrayPath.replace(/:[^/]+/g, "[^/]+")}$`);

      // Check if the input path matches the pattern
      if (pattern.test(path)) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    const state = location?.state;
    const newUrl = typeof state === 'string' ? state.split('?go=') : [];
    if (!!newUrl?.[1] && isPathInArray(newUrl?.[1], routeRoles[profile?.type]?.map((item) => item.path))) {
      navigate(newUrl?.[1]);
    } else {
      if (profile?.type !== USER_ROLES.ALLBRY_COUNSELLOR_ROLE) {
        UsersResponse().then((data) => {
          if (data && data.length > 1) {
            navigate(USERS_ACCOUNT_SELECTION);
          } else if (profile?.type === USER_ROLES.PRINCIPAL_ROLE) {
            if (
              ability.can(
                CUSTOM_USER_ROLES[USER_ROLES.PRINCIPAL_ROLE]
                  .ORGANIZATION_SETTINGS,
                USER_ROLES.PRINCIPAL_ROLE
              )
            ) {
              navigate(ORGANIZATION);
            } else {
              navigate(PROFILE + "/" + profile.id);
            }
          } else {
            if (
              ability.can(
                CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].APPOINTMENTS,
                USER_ROLES.COUNSELLOR_ROLE
              ) ||
              ability.can(
                CUSTOM_USER_ROLES[USER_ROLES.STUDENT_ROLE].APPOINTMENTS,
                USER_ROLES.STUDENT_ROLE
              )
            ) {
              navigate(APPOINTMENTS);
            } else {
              navigate(PROFILE + "/" + profile.id);
            }
          }
        });
      } else {
        navigate(ACCOUNT_SELECTION);
      }
    }
  }, []);

  return <></>;
};
