import {
  APPOINTMENTS,
  MESSAGES,
  PROFILE_STUDENT_VIEW_NOTE,
  PROFILE_STUDENT_VIEW_NOTES,
  PROFILE_STUDENT_VIEW_STATISTICS,
  PROFILE_STUDENT_VIEW_NOTES_ADD,
  APPOINTMENT_DETAIL,
  APPOINTMENTS_BOOKING,
  APPOINTMENTS_FREE_TIME,
  USERS_ACCOUNT_SELECTION,
  STUDENTS,
  REPORTS,
  ADD_CONFIG,
  STATISTICS,
  CONFIG,
  PROFILE_STUDENT_VIEW_REPORT,
  CREATE_OWN_TEMPLATE,
} from "@router/consts";
import { Calendar, ManyUsers, Statistics } from "@assets/icons";
import Chat from "@components/Navbar/ChatIcon";
import Counsellor from "@pages/Counsellor";
import { ability } from "ability/ability";
import { USER_ROLES, CUSTOM_USER_ROLES } from "@utils/consts";
import AppointmentIcon from "@components/Navbar/AppointmentIcon";
import TemplateIcon from "@assets/icons/Template";
import ReportIcon from "@assets/icons/ReportIcon";

export const COUNSELLOR_MENU_ROUTES = () => {
  let counsellorMenuRoutes = [];

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].APPOINTMENTS,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorMenuRoutes.push({
      name: "appointments",
      path: APPOINTMENTS,
      icon: AppointmentIcon,
      exact: true,
      isMenu: true,
      element: <Counsellor.Appointments />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].CHATS,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorMenuRoutes.push({
      name: "messages",
      exact: true,
      isMenu: true,
      icon: Chat,
      path: MESSAGES,
      element: <Counsellor.Messages />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].STUDENT_LIST,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorMenuRoutes.push({
      name: "students",
      exact: true,
      isMenu: true,
      icon: ManyUsers,
      path: STUDENTS,
      element: <Counsellor.Students />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].REPORTS,
      USER_ROLES.COUNSELLOR_ROLE
    ) ||
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].NOTES,
      USER_ROLES.COUNSELLOR_ROLE
    ) ||
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].INTERNAL_REPORT,
      USER_ROLES.COUNSELLOR_ROLE
    ) ||
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].ANONYMOUS_QUICK_REPORT,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorMenuRoutes.push({
      name: "reports",
      exact: true,
      isMenu: true,
      icon: ReportIcon,
      path: REPORTS,
      element: <Counsellor.Reports />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].STATISTICS,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorMenuRoutes.push({
      name: "statistics",
      exact: true,
      isMenu: true,
      icon: Statistics,
      path: STATISTICS,
      element: <Counsellor.Statistic />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].SURVEY_CONFIG,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorMenuRoutes.push({
      name: "config",
      exact: true,
      isMenu: true,
      icon: TemplateIcon,
      path: CONFIG,
      element: <Counsellor.Config />,
    });
  }

  return counsellorMenuRoutes;
};

export const COUNSELLOR_ROUTES = () => {
  let counsellorRoutes = COUNSELLOR_MENU_ROUTES();

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].APPOINTMENTS,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorRoutes.push({
      name: "appointmentBooking",
      exact: true,
      path: APPOINTMENTS_BOOKING,
      element: <Counsellor.Booking />,
    });
    counsellorRoutes.push({
      name: "appointmentFreeTime",
      exact: true,
      path: APPOINTMENTS_FREE_TIME,
      element: <Counsellor.FreeTime />,
    });
    counsellorRoutes.push({
      name: "appointmentDetail",
      path: APPOINTMENT_DETAIL,
      element: <Counsellor.AppointmentDetail />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].NOTES,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorRoutes.push({
      name: "studentNoteAdd",
      path: PROFILE_STUDENT_VIEW_NOTES_ADD,
      exact: true,
      element: <Counsellor.StudentNotesAdd />,
    });
  }

  counsellorRoutes.push({
    name: "studentNoteItem",
    path: PROFILE_STUDENT_VIEW_NOTE,
    element: <Counsellor.StudentNotesItem />,
  });

  counsellorRoutes.push({
    name: "studentReportItem",
    path: PROFILE_STUDENT_VIEW_REPORT,
    element: <Counsellor.StudentReportItem />,
  });

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].STATISTICS,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorRoutes.push({
      name: "studentStatistics",
      path: PROFILE_STUDENT_VIEW_STATISTICS,
      exact: true,
      element: <Counsellor.StudentStatistics />,
    });
  }

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].NOTES,
      USER_ROLES.COUNSELLOR_ROLE
    ) ||
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].REPORTS,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorRoutes.push({
      name: "studentNotes",
      path: PROFILE_STUDENT_VIEW_NOTES,
      exact: true,
      element: <Counsellor.StudentNotesList />,
    });
  }

  counsellorRoutes.push({
    name: "usersAccountSelection",
    path: USERS_ACCOUNT_SELECTION,
    exact: true,
    element: <Counsellor.UsersAccountSelection />,
  });

  if (
    ability.can(
      CUSTOM_USER_ROLES[USER_ROLES.COUNSELLOR_ROLE].SURVEY_CONFIG,
      USER_ROLES.COUNSELLOR_ROLE
    )
  ) {
    counsellorRoutes.push({
      name: "configCreate",
      path: ADD_CONFIG,
      exact: true,
      element: <Counsellor.ConfigCreate />,
    });

    counsellorRoutes.push({
      name: "createOwnTemplate",
      path: CREATE_OWN_TEMPLATE,
      exact: true,
      element: <Counsellor.CreateOwnTempLate />,
    });
  }
  return counsellorRoutes;
};
